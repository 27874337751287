import LuxLoyaltyTierLabel from 'components/Common/Labels/LuxLoyaltyTierLabel'
import LineChairWithAirplaneIcon from 'components/Luxkit/Icons/line/LineChairWithAirplaneIcon'
import LineRoomUpgradeIcon from 'components/Luxkit/Icons/line/LineRoomUpgradeIcon'
import LineTaxiIcon from 'components/Luxkit/Icons/line/LineTaxiIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import Group from 'components/utils/Group'
import React from 'react'

const BENEFIT_CONFIG: Utils.PartialRecord<App.LuxLoyaltyBenefit['code'], {
  label: string;
  Icon: React.ComponentType;
}> = {
  lounge_pass: {
    label: 'Free airport lounge passes',
    Icon: LineChairWithAirplaneIcon,
  },
  airport_transfer: {
    label: 'Free airport chauffeur service',
    Icon: LineTaxiIcon,
  },
  hotel_upgrade: {
    label: 'Guaranteed free room upgrade',
    Icon: LineRoomUpgradeIcon,
  },
}

interface Props {
  benefit: App.LuxLoyaltyBenefit | undefined;
  benefitCode: App.LuxLoyaltyBenefit['code'];
  tier: App.LuxLoyaltyTier;
}

function AccountDrawerBenefitHighlightItem(props: Props) {
  const { benefit, benefitCode, tier } = props

  const config = BENEFIT_CONFIG[benefitCode]

  if (!config) return null

  const { Icon, label } = config

  return (
    <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="center">
      <BodyTextBlock variant="small" startIcon={<Icon />}>
        {label}
      </BodyTextBlock>
      <LuxLoyaltyTierLabel kind="tertiary" tier={tier}>
        {benefit?.unlimited && 'Unlimited'}
        {benefit && !benefit.unlimited && `${benefit.remaining === 0 ? 'Redeemed' : `${benefit.remaining}x`}`}
      </LuxLoyaltyTierLabel>
    </Group>
  )
}

export default AccountDrawerBenefitHighlightItem
