import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import withMountGuard from 'hocs/withMountGuard'
import { useAppSelector } from 'hooks/reduxHooks'
import LuxLoyaltyProgramNameLogo from 'luxLoyalty/components/LuxLoyaltyProgramNameLogo'
import { LUX_LOYALTY } from 'luxLoyalty/constants/base'
import { getIsLuxLoyaltyEnabled, getHasLuxLoyaltyAccount } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import Caption from 'components/Luxkit/Typography/Caption'
import Divider from 'components/Luxkit/Divider'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import getLuxLoyaltyTierDisplayName from 'luxLoyalty/selectors/getLuxLoyaltyTierDisplayName'
import FormatDecimal from 'components/utils/Formatters/FormatDecimal'
import config from 'constants/config'
import { getPlural } from 'lib/string/pluralize'
import { clamp } from 'lib/maths/mathUtils'
import LuxLoyaltyAccountTileCircularProgressBar from 'luxLoyalty/components/LuxLoyaltyAccountTileCircularProgressBar'
import cn from 'clsx'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Clickable from 'components/Common/Clickable'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import BodyText from 'components/Luxkit/Typography/BodyText'
import getLuxLoyaltyNextTier from 'luxLoyalty/selectors/getLuxLoyaltyNextTier'
import AccountDrawerBenefitHighlights from './AccountDrawerBenefitHighlights'
import { DAY_MONTH_NAME } from 'constants/dateFormats'
import moment from 'moment'
import TextLink from 'components/Luxkit/TextLink'
import LoadingBox from 'components/Common/Loading/LoadingBox'

const Container = styled(Clickable)`
  padding: ${rem(12)} ${rem(16)};
  color: ${props => props.theme.palette.product.luxPlus.contrast};
  border-radius: ${props => props.theme.borderRadius.M};
  /** To see the effect of the border-radius */
  overflow: hidden;
  
  &.tier-bronze {
    background: ${props => props.theme.palette.product.luxLoyalty.bronze.gradient};
    color: ${props => props.theme.palette.product.luxLoyalty.bronze.normalContrast};
  }
  
  &.tier-silver {
    background: ${props => props.theme.palette.product.luxLoyalty.silver.gradient};
    color: ${props => props.theme.palette.product.luxLoyalty.silver.normalContrast};
  }
  
  &.tier-gold {
    background: ${props => props.theme.palette.product.luxLoyalty.gold.gradient};
    color: ${props => props.theme.palette.product.luxLoyalty.gold.normalContrast};
  }
  
  &.tier-platinum {
    background: ${props => props.theme.palette.product.luxLoyalty.platinum.gradient};
    color: ${props => props.theme.palette.product.luxLoyalty.platinum.normalContrast};
  }

  &:focus {
    outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid  ${props => props.theme.palette.neutral.default.five});
    outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
  }
`

const LoadingContainer = styled.div`
  background-color: ${props => props.theme.palette.neutral.default.seven};
  padding: ${rem(12)} ${rem(16)};
  width: ${rem(320)};
  height: ${rem(270)};
`

interface Props {
  onLinkClick: () => void
}

function AccountMenuLuxLoyaltyDetailsTile({ onLinkClick }: Props) {
  const luxLoyaltyAccount = useAppSelector(state => state.luxLoyalty.account)
  const nextTier = useAppSelector(getLuxLoyaltyNextTier)
  const hasLuxLoyaltyAccount = useAppSelector(getHasLuxLoyaltyAccount)

  const accountTier = luxLoyaltyAccount.data?.tier
  const points = luxLoyaltyAccount?.data?.points ?? 0
  const statusCredits = luxLoyaltyAccount?.data?.statusCredits ?? 0
  const totalCreditsForTierRetention = luxLoyaltyAccount?.data?.totalCreditsForTierRetention ?? 0
  const totalCreditsForTierUpgrade = luxLoyaltyAccount?.data?.totalCreditsForTierUpgrade ?? 0
  const nextTierCreditsRequired = accountTier === 'platinum' ? totalCreditsForTierRetention : totalCreditsForTierUpgrade
  const progress = Math.floor((statusCredits / (accountTier === 'platinum' ? totalCreditsForTierRetention : nextTierCreditsRequired)) * 100)
  const clampedProgress = clamp(0, progress, 100)

  if (luxLoyaltyAccount.fetching) {
    return <LoadingContainer>
      <VerticalSpacer gap={20}>
        <VerticalSpacer gap={4}>
          <Group direction="horizontal" horizontalAlign="space-between">
            <TextLoadingBox width={72} lines={1} typography="body-medium" />
            <TextLoadingBox width={72} lines={1} typography="body-medium" />
          </Group>
          <Group direction="horizontal" horizontalAlign="space-between">
            <TextLoadingBox width={72} lines={1} typography="body-medium" />
            <TextLoadingBox width={120} lines={1} typography="body-medium" />
          </Group>
        </VerticalSpacer>
        <Group direction="horizontal" horizontalAlign="center" gap={20}>
          <LoadingBox width={64} />
          <VerticalSpacer gap={4} >
            <TextLoadingBox width={96} lines={1} typography="body-medium" />
            <TextLoadingBox width={72} lines={1} typography="body-medium" />
          </VerticalSpacer>
        </Group>
        <VerticalSpacer gap={4}>
          <TextLoadingBox width={72} lines={1} typography="body-medium" />
          <TextLoadingBox width={240} lines={1} typography="body-medium" />
          <TextLoadingBox width={240} lines={1} typography="body-medium" />
        </VerticalSpacer>
        <Group direction="horizontal" horizontalAlign="space-between">
          <TextLoadingBox width={72} lines={1} typography="body-medium" />
          <TextLoadingBox width={72} lines={1} typography="body-medium" />
        </Group>
      </VerticalSpacer>
    </LoadingContainer>
  }

  if (!hasLuxLoyaltyAccount || luxLoyaltyAccount.error) return null

  return <Container to={`/${LUX_LOYALTY.LANDING_PAGE}`} onClick={onLinkClick} className={cn(`tier-${accountTier}`)}>
    <VerticalSpacer gap={12}>
      <VerticalSpacer gap={4}>
        <Group direction="horizontal" horizontalAlign="space-between">
          <LuxLoyaltyProgramNameLogo width={72} />
          {accountTier && <BodyText variant="medium" format="uppercase">{getLuxLoyaltyTierDisplayName(accountTier)}</BodyText>}
        </Group>
        <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="end">
          <Caption variant="large">Member {luxLoyaltyAccount.data?.accountNumber}</Caption>
          <Group direction="horizontal" gap={4} verticalAlign="center">
            <Heading variant="heading6">
              <FormatDecimal value={points} />
            </Heading>
            <Caption variant="medium" weight="normal">pts</Caption>
          </Group>
        </Group>
      </VerticalSpacer>
      <VerticalSpacer gap={20}>
        <Group direction="horizontal" verticalAlign="center" horizontalAlign="center" gap={20}>
          <LuxLoyaltyAccountTileCircularProgressBar
            progress={clampedProgress}
            tier={accountTier!}
          >
            <Group direction="vertical" horizontalAlign="center" gap={2}>
              <BodyText variant="medium" weight="bold">
                <FormatDecimal value={statusCredits} />
              </BodyText>
              <Divider kind="primary" />
              <Caption variant="small">
                <FormatDecimal value={nextTierCreditsRequired} />
              </Caption>
            </Group>
          </LuxLoyaltyAccountTileCircularProgressBar>
          <BodyText variant="small">
            <b>{nextTierCreditsRequired - statusCredits} {getPlural(config.luxLoyalty.statusCreditsUnit)}</b><br/>
            to {accountTier === 'platinum' ? `retain ${getLuxLoyaltyTierDisplayName(accountTier)}` : `upgrade to ${getLuxLoyaltyTierDisplayName(nextTier!)}`}
          </BodyText>
        </Group>
        {luxLoyaltyAccount.data?.benefits && luxLoyaltyAccount.data?.benefits.length > 0 && <VerticalSpacer gap={8}>
          <BodyText variant="small" weight="bold">Your top benefits</BodyText>
          <AccountDrawerBenefitHighlights tier={accountTier || 'bronze'} benefits={luxLoyaltyAccount.data?.benefits} />
        </VerticalSpacer>}
        <Group direction="horizontal" horizontalAlign="space-between">
          <Caption variant="medium" weight="normal">Review date {moment(luxLoyaltyAccount.data?.statusReviewDate).format(DAY_MONTH_NAME)}</Caption>
          <TextLink variant="default" size="small">
            View membership
          </TextLink>
        </Group>
      </VerticalSpacer>
    </VerticalSpacer>
  </Container>
}

export default withMountGuard(() => {
  return useAppSelector(getIsLuxLoyaltyEnabled)
})(AccountMenuLuxLoyaltyDetailsTile)
