import React from 'react'
import Divider from 'components/Luxkit/Divider'
import AccountDrawerBenefitHighlightItem from './AccountDrawerBenefitHighlightItem'
import { arrayToObject } from 'lib/array/arrayUtils'

const TIER_BENEFITS: Record<App.LuxLoyaltyTier, Array<App.LuxLoyaltyBenefit['code']>> = {
  bronze: [],
  silver: ['lounge_pass'],
  gold: ['airport_transfer', 'hotel_upgrade', 'lounge_pass'],
  platinum: ['airport_transfer', 'hotel_upgrade', 'lounge_pass'],
}

interface Props {
  tier: App.LuxLoyaltyTier;
  benefits: Array<App.LuxLoyaltyBenefit>;
}

function AccountDrawerBenefitHighlights(props: Props) {
  const { tier, benefits } = props

  const tierBenefits = TIER_BENEFITS[tier]
  const benefitsMap = arrayToObject(benefits, benefit => benefit.code)

  return (
    <>
      {tierBenefits.map((benefitCode, index) => (
        <React.Fragment key={benefitCode}>
          {index > 0 && <Divider kind="secondary" />}
          <AccountDrawerBenefitHighlightItem
            benefitCode={benefitCode}
            benefit={benefitsMap[benefitCode]}
            tier={tier}
          />
        </React.Fragment>
      ))}
    </>
  )
}

export default AccountDrawerBenefitHighlights
